export const getFrenchCategory = (englishCategory) => {
  let category;
  switch (englishCategory) {
    case "contextual":
      category = "contextuels";
      break;
    case "historical":
      category = "historiques";
      break;
    case "explicit":
      category = "explicites";
      break;
    case "characters":
      category = "personnages";
      break;
    case "contemporary":
      category = "contemporains";
      break;
    default:
      category = undefined;
  }
  return category;
};

// export const categoryMap_FrEn = new Map([
//   ["contextual", "contextuels"],
//   ["historical", "historiques"],
//   ["explicit", "explicites"],
//   ["characters", "personnages"],
//   ["contemporary", "contemporains"],
// ]);

// export const categoryMap_En = new Map([
//   ["contextual", "Contextual Hate"],
//   ["historical", "Historical Canadian Hate Groups"],
//   ["explicit", "Explicit Hate"],
//   ["characters", "Internet memes with hateful uses"],
//   ["contemporary", "contemporains"],
// ]);

// export const categoryMap_Fr = new Map([
//   ["contextual", "contextuels"],
//   ["historical", "historiques"],
//   ["explicit", "explicites"],
//   ["characters", "personnages"],
//   ["contemporary", "contemporains"],
// ]);
