import React from "react";
import { usePagination } from "../hooks/usePagination";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrev = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  const listStyle = "m-0 p-0 cursor-pointer hover:underline hover:text-red-400";

  return (
    <div className="grid">
      <ul className="list-none flex gap-3 justify-self-center pl-0 justify-center m-0 p-0">
        {/* Left navigation arrow */}
        <li
          onClick={onPrev}
          className={`${listStyle} ${currentPage === 1 ? "hidden" : ""}`}
        >
          &#60;
        </li>
        {paginationRange.map((pageNumber, i) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === "DOTS") {
            return (
              <li
                className={`${listStyle} hover:no-underline hover:text-gray-700 cursor-default`}
                key={`DOTS-${i}`}
              >
                &#8230;
              </li>
            );
          }
          //Render page pills
          return (
            <li
              // className={`${currentPage === pageNumber ? "text-red-500" : ""}`}
              className={`${listStyle} ${
                currentPage === pageNumber && "text-red-400 font-bold underline"
              } `}
              key={`pill-${i}-${pageNumber}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/* Right navigation arrow */}
        <li
          onClick={onNext}
          className={`${listStyle} ${currentPage === lastPage ? "hidden" : ""}`}
        >
          &#62;
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
