import React, { Fragment, useMemo } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import SEO from "./seo";
import PortableText from "./portableText";
import { Figure } from "./Figure";
import ConditionalWrapper from "./ConditionalWrapper";
import { getFrenchCategory } from "../helpers";
import { useState } from "react";
import Pagination from "./Pagination";
import SearchUI from "./search/SearchUI";

export const idify = (str) =>
  `symbol-${str
    .toLowerCase()
    .replace(/[ _]/g, "-")
    .replace(/[/\\]/g, "slash")
    .replace(/[^\w-]/g, "")
    .slice(0, 16)}`;

export function SymbolsSection({
  symbols,
  isSubSection = false,
  isFrench = false,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const currentSymbolData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return symbols.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, symbols]);

  if (!symbols || symbols.length === 0) return null;
  const Heading = isSubSection ? "h3" : "h2";

  return (
    <div className="prose dark:prose-invert prose-lg py-12 public-symbols-page-content">
      {currentSymbolData.map((symbol) => {
        let category = symbol.category;
        if (isFrench) {
          category = getFrenchCategory(symbol.category);
        }
        return (
          <Fragment key={symbol.id}>
            <div className="section-content-wrapper">
              <ConditionalWrapper
                condition={symbol.slug?.current}
                wrapper={(children) => (
                  <Link
                    to={`/guide${isFrench ? "/fr" : ""}/${category}/${
                      symbol.slug.current
                    }`}
                    className="no-underline"
                  >
                    {children}
                  </Link>
                )}
              >
                <Heading id={symbol.id}>{symbol.title}</Heading>
              </ConditionalWrapper>
              <PortableText blocks={symbol.description} />
              <Figure value={symbol.mainImage} />
            </div>
            <div className="section-images hidden xl:block" aria-hidden="true">
              <Figure value={symbol.mainImage} />
            </div>
          </Fragment>
        );
      })}
      <Pagination
        currentPage={currentPage}
        totalCount={symbols.length}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

const sharedDataQuery = graphql`
  query PublicSymbolPageStaticQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      conclusionFrench: _rawSymbolsPageConclusionFrench(
        resolveReferences: { maxDepth: 5 }
      )
      conclusion: _rawSymbolsPageConclusion(resolveReferences: { maxDepth: 5 })
    }
  }
`;

function PublicSymbolPage({ title, data, isFrench = false }) {
  const sharedData = useStaticQuery(sharedDataQuery);

  const description =
    data.page &&
    (isFrench
      ? data.page.descriptionFrench || data.page.description
      : data.page.description);
  const symbols = data.symbols.nodes;
  const conclusion =
    sharedData.page &&
    (isFrench
      ? sharedData.page.conclusionFrench || sharedData.page.conclusion
      : sharedData.page.conclusion);

  const symbolsWithId = useMemo(
    () =>
      symbols
        .map((symbol) => {
          const title = isFrench
            ? symbol.title_french || symbol.title
            : symbol.title;
          const description = isFrench
            ? symbol._rawDescriptionFrench || symbol._rawDescription
            : symbol._rawDescription;
          const alt = isFrench
            ? symbol.mainImage?.alt_french || symbol.mainImage?.alt
            : symbol.mainImage?.alt;
          const caption = isFrench
            ? symbol.mainImage?.caption_french || symbol.mainImage?.caption
            : symbol.mainImage?.caption;
          return {
            ...symbol,
            id: idify(title),
            title,
            mainImage: symbol.mainImage && {
              ...symbol.mainImage,
              alt,
              caption,
            },
            description,
          };
        })
        .sort((a, b) =>
          a.title.localeCompare(b.title, isFrench ? "fr-CA" : "en-CA")
        ),
    [symbols, isFrench]
  );

  return (
    <>
      <SEO title={title} />
      <div className="prose dark:prose-invert prose-lg md:pt-12">
        <h1>{title}</h1>
      </div>
      {description && (
        <div className="prose dark:prose-invert prose-lg italic pt-12 md:pt-0">
          {description}
        </div>
      )}
      <SearchUI isFrench={isFrench} />
      <SymbolsSection symbols={symbolsWithId} isFrench={isFrench} />
      {conclusion && (
        <div className="prose dark:prose-invert prose-lg pt-4 pb-16 md:pt-8 italic text-center md:text-left">
          <PortableText blocks={conclusion} />
        </div>
      )}
    </>
  );
}

export default PublicSymbolPage;
