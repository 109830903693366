import React from "react";
import { useState } from "react";

const FilterFor = ({ symbols, setSymbols, isFrench = false }) => {
  const [allSymbols] = useState(symbols);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [
    {
      title: "Explicit Hate",
      titleFr: "Haine explicite",
      value: "explicit",
    },
    {
      title: "Contextual Hate Symbols and Slogans",
      titleFr: "Haine contextuelle",
      value: "contextual",
    },
    {
      title: "Meme Characters with Hateful Uses",
      titleFr: "Mèmes avec usage haineux",
      value: "characters",
    },
    {
      title: "Contemporary Canadian Hate Groups",
      titleFr: "Groupes haineux canadiens contemporains",
      value: "contemporary",
    },
    {
      title: "Historical Canadian Hate Groups",
      titleFr: "Groupes haineux canadiens historiques",
      value: "historical",
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "all") {
      setSymbols(allSymbols);
      setSelectedCategory(null);
    } else {
      setSymbols(symbols.filter((symbol) => symbol.category === value));
      const result = categories.find((category) => category.value === value);
      setSelectedCategory(isFrench ? result.titleFr : result.title);
    }
  };

  return (
    <div className="mt-8 font-sans max-w-2xl">
      <form>
        <h3 className="block text-sm font-medium text-gray-700">
          {isFrench ? "Filtrer par catégorie" : "Filter by category"}
        </h3>
        <select
          onChange={(e) => handleChange(e)}
          className=" border-gray-200 rounded-md min-w-fit mt-1 "
        >
          <option value="all" selected className="text-gray-500">
            {isFrench ? "Afficher toutes" : "Show all"}
          </option>
          {categories.map((category, i) => (
            <option key={`${category.value}`} value={category.value}>
              {isFrench ? category.titleFr : category.title}
            </option>
          ))}
        </select>
      </form>

      <div className={`${!selectedCategory && "invisible"} mt-5`}>
        {isFrench
          ? "Affichage de tous les enregistrements pour:"
          : "Showing all records for:"}{" "}
        <span className="italic">{selectedCategory}</span>
      </div>
    </div>
  );
};

export default FilterFor;
