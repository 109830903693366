import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";

import SEO from "../../components/seo";
import PortableText from "../../components/portableText";
import { SymbolsSection, idify } from "../../components/public-symbol-page";
import FilterFor from "../../components/FilterFor";
import SearchUI from "../../components/search/SearchUI";

export const query = graphql`
  query AllSymbolsPageQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSymbolsPageAllIntroduction(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageConclusion(resolveReferences: { maxDepth: 5 })
    }
    symbols: allSanitySymbol(
      filter: { hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        category
        slug {
          current
        }
        mainImage {
          ...SanityImage
          alt
          caption
        }
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

const AllSymbolsPage = ({ data = {} }) => {
  const introduction = data.page?._rawSymbolsPageAllIntroduction;

  const symbolsWithId = useMemo(
    () =>
      data.symbols.nodes
        .map((symbol) => {
          const title = symbol.title;
          const description = symbol._rawDescription;
          const alt = symbol.mainImage?.alt;
          const caption = symbol.mainImage?.caption;
          return {
            ...symbol,
            id: idify(title),
            title,
            mainImage: symbol.mainImage && {
              ...symbol.mainImage,
              alt,
              caption,
            },
            description,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title, "en-CA")),
    [data.symbols.nodes]
  );
  const [symbols, setSymbols] = useState(symbolsWithId);

  const conclusion = data.page?._rawSymbolsPageConclusion;

  return (
    <>
      <SEO title="Full List" />
      <div className="prose dark:prose-invert prose-lg md:pt-12">
        <h1>Guide to Online Hate: Full List</h1>
        {introduction && (
          <div className="pt-1 md:pt-2">
            <PortableText blocks={introduction} />
          </div>
        )}
      </div>
      <SearchUI />
      <FilterFor symbols={symbolsWithId} setSymbols={setSymbols} />
      <SymbolsSection symbols={symbols} />

      {conclusion && (
        <div className="prose dark:prose-invert prose-lg pt-4 pb-16 md:pt-8 italic text-center md:text-left">
          <PortableText blocks={conclusion} />
        </div>
      )}
    </>
  );
};

export default AllSymbolsPage;
